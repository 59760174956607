import { ResponsiveContainer } from "recharts";
import JVMAP from "../components/JVMAP";
import { connect } from "react-redux";
import {
  filterData,
  selectCountry,
  selectRegion,
} from "../state/actions/country_selector";
import { useState } from "react";
import { useEffect } from "react";
import { store } from "../state/reducers/store";
import LineGraph from "../components/LineGraph";
import { africanData } from "../africandata";

export function DashBoard() {
  const [locations, setLocations] = useState([]);
  const [country, setCountry] = useState(null);
  const [period, setPeriod] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [malariaData, setMalariaData] = useState({});
  const [regionsData, setRegions] = useState([]);
  const [region, setRegion] = useState(null);
  const [countries, setFilterCountries] = useState([]);

  useEffect(() => {
    updateState();
    store.subscribe(updateState);
  }, []);

  function updateState() {
    const state = store.getState();
    setMalariaData(state?.malariadata);
    setFilteredData(state?.malariadata?.activeCountry);
    setLocations(state?.malariadata?.locations);
    setRegions(state?.malariadata?.regions);
    setFilterCountries(state?.malariadata?.countriesByRegion);
  }
  console.log(regionsData);
  console.log("malaria data", malariaData);

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
    store.dispatch(filterData(country, event.target.value));
  };

  return (
    <>
      <div>
        <div
          className="text-center"
          style={{ "margin-left": "20px", "margin-top": "15px" }}
        >
          <h3 className="h3">Malaria</h3>
        </div>
        <div class="content-body">
          <div
            className="row"
            style={{ "margin-left": "20px", "margin-top": "10px" }}
          >
            <div className="col-md-2">
              Select Region:
              <select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                name="region"
                onChange={(event) => {
                  setRegion(event.target.value);
                  store.dispatch(selectRegion(event.target.value));
                }}
              >
                <option selected>All</option>
                {regionsData?.data?.map((region) => (
                  <option value={region.Region} key={region.Region}>
                    {region.Region}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-2">
              Select Country:
              <select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                name="country"
                onChange={(event) => {
                  setCountry(event.target.value);
                  store.dispatch(selectCountry(event.target.value));
                  store.dispatch(filterData(event.target.value, "2020"));
                  setCountry(event.target.value);
                }}
              >
                <option selected>All</option>
                {countries?.map((location) => (
                  <option value={location.Title} key={location.Title}>
                    {location.Title}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-2">
              Select Period:
              <select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
                name="period"
                onChange={handlePeriodChange}
              >
                <option selected>
                  {malariaData?.selectedCountryPeriods !== undefined
                    ? malariaData?.selectedCountryPeriods[1]?.Period
                    : "All"}{" "}
                </option>
                {malariaData?.selectedCountryPeriods?.map((period) => (
                  <option value={period?.Period} key={period?.Period}>
                    {period?.Period}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12 col-xxl-12">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="row">
                          <div class="col-xl-3 col-sm-6">
                            <div class="card bg-primary text-white">
                              <div class="card-header border-0 flex-wrap">
                                <div class="revenue-date">
                                  <span>
                                    <h4
                                      class="text-white"
                                      style={{ "font-size": "130%" }}
                                    >
                                      Est. number of cases
                                    </h4>
                                  </span>
                                  <h4 class="text-white">
                                    {malariaData?.selectedCountryPeriods
                                      ?.length === 1 ||
                                    malariaData?.selectedCountryPeriods
                                      ?.length === 0
                                      ? 0
                                      : filteredData?.value?.cases?.cases?.toLocaleString() ??
                                        africanData?.data[1]?.value?.cases?.cases?.toLocaleString()}
                                  </h4>
                                </div>
                                <div class="avatar-list avatar-list-stacked me-2"></div>
                              </div>
                              <div class="card-body pb-0 custome-tooltip d-flex align-items-center">
                                <div id="chartBar" class="chartBar"></div>
                                <div></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-3 col-sm-6">
                            <div class="card bg-secondary  text-white">
                              <div class="card-header border-0 flex-wrap">
                                <div class="revenue-date text-white">
                                  <span>
                                    <h4
                                      class="text-white"
                                      style={{ "font-size": "130%" }}
                                    >
                                      Est. number of deaths
                                    </h4>
                                  </span>
                                  <h4 class="text-white">
                                    {malariaData?.selectedCountryPeriods
                                      ?.length === 1 ||
                                    malariaData?.selectedCountryPeriods
                                      ?.length === 0
                                      ? 0
                                      : filteredData?.value?.death?.death?.toLocaleString() ??
                                        africanData?.data[1]?.value?.death?.death?.toLocaleString() ??
                                        0}
                                  </h4>
                                </div>
                                <div class="avatar-list avatar-list-stacked me-2"></div>
                              </div>
                              <div class="card-body pb-0 custome-tooltip d-flex align-items-center">
                                <div id="chartBar" class="chartBar"></div>
                                <div></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-3 col-sm-6">
                            <div
                              class="card  text-white"
                              style={{ background: "#348F41" }}
                            >
                              <div class="card-header border-0 flex-wrap">
                                <div class="revenue-date text-white">
                                  <span>
                                    <h4
                                      class="text-white"
                                      style={{ "font-size": "130%" }}
                                    >
                                      Est. incidence rate (per 1K)
                                    </h4>
                                  </span>

                                  <h4 class="text-white">
                                    {malariaData?.selectedCountryPeriods
                                      ?.length === 1 ||
                                    malariaData?.selectedCountryPeriods
                                      ?.length === 0
                                      ? 0
                                      : filteredData?.value?.cases?.incidence ??
                                        africanData?.data[1]?.value?.cases?.incidence?.toLocaleString() ??
                                        0}
                                  </h4>
                                </div>
                                <div class="avatar-list avatar-list-stacked me-2"></div>
                              </div>
                              <div class="card-body pb-0 custome-tooltip d-flex align-items-center">
                                <div id="chartBar" class="chartBar"></div>
                                <div></div>
                              </div>
                            </div>
                          </div>

                          <div class="col-xl-3 col-sm-6">
                            <div
                              class="card text-white"
                              style={{ background: "#A6228C" }}
                            >
                              <div class="card-header border-0">
                                <div class="revenue-date">
                                  <span>
                                    <h4
                                      class="text-white"
                                      style={{ "font-size": "120%" }}
                                    >
                                      Est. mortality rate (per 100K)
                                    </h4>
                                  </span>
                                  <h4 class="text-white">
                                    {malariaData?.selectedCountryPeriods
                                      ?.length === 1 ||
                                    malariaData?.selectedCountryPeriods
                                      ?.length === 0
                                      ? 0
                                      : filteredData?.value?.death
                                          ?.mortalityRate ??
                                        africanData?.data[1]?.value?.death?.mortalityRate?.toLocaleString() ??
                                        0}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <div class="card overflow-hidden">
                          <div class="card-header border-0 pb-0 flex-wrap">
                            <h4 class="heading mb-0">
                              Trends over the past few Years
                            </h4>
                          </div>
                          <div className="card-body">
                            <br></br>
                            <br></br>

                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "400px",
                                paddingBottom: "250px",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  top: 0,
                                }}
                              >
                                <ResponsiveContainer>
                                  <LineGraph malariaData={malariaData} />
                                </ResponsiveContainer>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <a
                          href="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="This Map is Static. Full Functionality is Coming Soon"
                        >
                          {/* class="btn btn-secondary" data-toggle="tooltip" data-placement="top" title="Tooltip on top" */}
                          <div class="card justify-content-md-center">
                            <div class="card-header border-0 flex-wrap">
                              <h4 class="heading mb-0">
                                Malaria incidence map with rainfall 2020
                              </h4>
                            </div>
                            <div class="card-body p-0">
                              <JVMAP></JVMAP>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-12 col-xxl-12">
                <div class="card">
                  <div class="card-body p-0">
                    <div class="table-responsive active-projects">
                      <div class="tbl-caption">
                        <h4 class="heading mb-0">Rankings</h4>
                      </div>
                      <table id="projects-tbl" class="table">
                        <thead>
                          <tr>
                            <th colSpan={2}>Indicator</th>
                            <th colSpan={2}>Ranking in Africa</th>
                            <th colSpan={2}>African Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Est. mortality Rate(per 100k)</td>
                            <td>
                              <div class="d-flex align-items-center">
                                <p class="mb-0 ms-2"></p>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="tbl-progress-box">
                                <span>
                                  {filteredData?.value?.death?.RankAfrica ?? 0}
                                </span>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="avatar-list avatar-list-stacked"></div>
                            </td>
                            <td class="pe-0"></td>
                            <td>
                              <span>
                                {filteredData?.value?.death?.AfricanValue ?? 0}
                              </span>
                            </td>
                          </tr>

                          <tr>
                            <td>Est. Incidence Rate(per 1k)</td>
                            <td>
                              <div class="d-flex align-items-center">
                                <p class="mb-0 ms-2"></p>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="tbl-progress-box">
                                {filteredData?.value?.cases?.RankAfrica ?? 0}
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="avatar-list avatar-list-stacked"></div>
                            </td>
                            <td class="pe-0"></td>
                            <td>
                              {filteredData?.value?.cases?.AfricanValue ?? 0}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    malariaData: state.malariadata,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectCountry: (props) => dispatch(selectCountry(props)),
    filterData: (country, period) => dispatch(filterData(country, period)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
