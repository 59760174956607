import { Component } from "react";
import { Link } from "react-router-dom";
import { africanData } from "../africandata";

class LandingPage extends Component {
  state = {
    data: [
      {
        title: "Malaria",
        image: "malaria-white.svg",
        cases:
          africanData?.data[1]?.value?.cases?.cases?.toLocaleString() +
          "  Reported Cases",
        timeline: "Jan 2020 - Dec 2020",
        link: "/dashboard",
        borderColor: "#32A28B",
        backgroundColor: "#348F41",
      },
      {
        title: "HIV / AIDS",
        image: "hiv-aids-white.svg",
        cases: "221+ Cases Reported",
        timeline: "Coming Soon",
        link: "#",
        borderColor: "#32A28B",
        backgroundColor: "#38B49B",
      },
      {
        title: "TB",
        image: "tb-white.svg",
        cases: "2001 Cases Reported",
        timeline: "Coming Soon",
        link: "#",
        borderColor: "#32A28B",
        backgroundColor: "#38B49B",
      },
      {
        title: "SDG",
        image: "sdg-white.svg",
        cases: "2001 Cases Reported",
        timeline: "Coming Soon",
        link: "#",
        borderColor: "#E61E64",
        backgroundColor: "#CF1B5A",
      },
      {
        title: "Immunisation",
        image: "immunisation-white.svg",
        cases: "2001 Cases Reported",
        timeline: "Coming Soon",
        link: "#",
        borderColor: "#E61E64",
        backgroundColor: "#CF1B5A",
      },
      {
        title: "Mortality and life expectancy",
        image: "motality-white.svg",
        cases: "2001 Cases Reported",
        timeline: "Coming Soon",
        link: "#",
        borderColor: "#E61E64",
        backgroundColor: "#CF1B5A",
      },
    ],
  };
  render() {
    return (
      <div>
        <div>
          <div>
            <div className="row">
              <div className="bg-white py-12">
                <div className="text-center">
                  <h3 className="h3">Data Visualisations </h3>
                </div>
                <div className="card-header border-0 pb-3"></div>

                <div className="container">
                  {/* <div class="row">
                    {this.state.data.map((data) => {
                      return (
                        <div class="col-md-4">
                          <div class="card p-3">
                            <div class="d-flex flex-row mb-3">
                              <div
                                className="p-2"
                                style={{
                                  width: "70px",
                                  height: "70px",
                                  backgroundColor: data.backgroundColor,
                                  border: "2px solid" + data.borderColor,
                                }}
                              >
                                <img
                                  src={"images/images/" + data.image}
                                  alt={data.title}
                                  style={{objectFit: 'cover'}}
                                />
                              </div>

                              <div class="d-flex flex-column ml-2">
                                <span className="fs-4" style={{color: data.borderColor}}>{data.title}</span>
                                <span class="text-black-50">{data.cases}</span>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between install mt-3">
                              <span>{data.timeline}</span>
                              <span class="text-primary fs-4">
                                <Link className="text-hover-afroSkyBlue" to={data.link} style={{color : data.borderColor}}>
                                  View&nbsp;<i class="fa fa-angle-right"></i>
                                </Link>
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div> */}
                  <div class="row">
                    {this.state.data.map((data) => {
                      return (
                        <div class="col-md-4">
                          <Link
                            className="text-hover-afroCoral fw-bold text-white"
                            to={data.link}
                          >
                            <div
                              className="card p-3"
                              style={{
                                backgroundColor:
                                  data.timeline === "Coming Soon"
                                    ? "#CCCCCC"
                                    : data.backgroundColor,
                                color:
                                  data.timeline === "Coming Soon"
                                    ? "#666666!important"
                                    : "",
                                border:
                                  data.timeline === "Coming Soon"
                                    ? ""
                                    : "2px solid" + data.borderColor,
                                cursor:
                                  data.timeline === "Coming Soon"
                                    ? "not-allowed"
                                    : "",
                              }}
                            >
                              <div class="d-flex flex-row mb-3">
                                <div
                                  className="p-2"
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                  }}
                                >
                                  <img
                                    src={"images/images/" + data.image}
                                    alt={data.title}
                                    style={{ objectFit: "cover" }}
                                  />
                                </div>

                                <div class="d-flex flex-column ml-2">
                                  <span className="fs-4 text-white h4">
                                    {data.title}
                                  </span>
                                  <span class="text-white">{data?.cases}</span>
                                </div>
                              </div>
                              <div class="d-flex justify-content-between install mt-3">
                                <span className="text-white">
                                  {data.timeline}
                                </span>
                                <span class="text-primary fs-4"></span>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LandingPage;
