import { Component } from "react";
import QRCode from "react-qr-code";
class QRCodeComponent extends Component {
  state = {};
  render() {
    return (
      <div
        style={{
          height: "auto",
          margin: "0 auto",
          maxWidth: 300,
          width: "100%",
        }}
      >
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value="google.com"
          viewBox={`0 0 256 256`}
        />
      </div>
    );
  }
}
export default QRCodeComponent;
