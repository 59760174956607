import { Component } from 'react'

class JVMAP extends Component{
     state = {};

     render(){
        return(
            <div className='justify-content-md-center'>
            <img src='images/images/map-leaf.png' height={500} width={600}></img>
            </div>
        );
     }

}

export default JVMAP;