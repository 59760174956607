export const africanData = {
  data: [
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2021,
      value: {
        cases: {
          cases: 233829469,
          incidence: 201.5,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2020,
      value: {
        cases: {
          cases: 231933551,
          incidence: 205.2,
        },
        death: {
          death: 592135,
          mortalityRate: 52.2,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2019,
      value: {
        cases: {
          cases: 217915995,
          incidence: 197.8,
        },
        death: {
          death: 534201,
          mortalityRate: 48.5,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2018,
      value: {
        cases: {
          cases: 215442557,
          incidence: 200.7,
        },
        death: {
          death: 533041,
          mortalityRate: 49.7,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2017,
      value: {
        cases: {
          cases: 218442652,
          incidence: 208.8,
        },
        death: {
          death: 542464,
          mortalityRate: 51.9,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2016,
      value: {
        cases: {
          cases: 210772603,
          incidence: 206.8,
        },
        death: {
          death: 528332,
          mortalityRate: 51.8,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2015,
      value: {
        cases: {
          cases: 211088425,
          incidence: 212.6,
        },
        death: {
          death: 527099,
          mortalityRate: 53.1,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2014,
      value: {
        cases: {
          cases: 211298145,
          incidence: 218.6,
        },
        death: {
          death: 534225,
          mortalityRate: 55.3,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2013,
      value: {
        cases: {
          cases: 213649438,
          incidence: 227.1,
        },
        death: {
          death: 555751,
          mortalityRate: 59.1,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2012,
      value: {
        cases: {
          cases: 214135800,
          incidence: 233.9,
        },
        death: {
          death: 575258,
          mortalityRate: 62.8,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2011,
      value: {
        cases: {
          cases: 214366640,
          incidence: 240.6,
        },
        death: {
          death: 607628,
          mortalityRate: 68.2,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2010,
      value: {
        cases: {
          cases: 216715745,
          incidence: 250.1,
        },
        death: {
          death: 645873,
          mortalityRate: 74.5,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2009,
      value: {
        cases: {
          cases: 215949727,
          incidence: 256.1,
        },
        death: {
          death: 671207,
          mortalityRate: 79.6,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2008,
      value: {
        cases: {
          cases: 211952696,
          incidence: 258.4,
        },
        death: {
          death: 678128,
          mortalityRate: 82.7,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2007,
      value: {
        cases: {
          cases: 211772674,
          incidence: 265.3,
        },
        death: {
          death: 697945,
          mortalityRate: 87.4,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2006,
      value: {
        cases: {
          cases: 212072558,
          incidence: 272.9,
        },
        death: {
          death: 714572,
          mortalityRate: 91.9,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2005,
      value: {
        cases: {
          cases: 212855261,
          incidence: 281.3,
        },
        death: {
          death: 723262,
          mortalityRate: 95.6,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2004,
      value: {
        cases: {
          cases: 216333552,
          incidence: 293.6,
        },
        death: {
          death: 749959,
          mortalityRate: 101.8,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2003,
      value: {
        cases: {
          cases: 216399451,
          incidence: 301.6,
        },
        death: {
          death: 773523,
          mortalityRate: 107.8,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2002,
      value: {
        cases: {
          cases: 213799381,
          incidence: 305.8,
        },
        death: {
          death: 796770,
          mortalityRate: 114.0,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2001,
      value: {
        cases: {
          cases: 215879551,
          incidence: 317.0,
        },
        death: {
          death: 837574,
          mortalityRate: 123.0,
        },
      },
    },
    {
      location: {
        Region: "Africa",
        CountryCode: "AFR",
        CountryName: "Africa",
      },
      Period: 2000,
      value: {
        cases: {
          cases: 211089818,
          incidence: 318.0,
        },
        death: {
          death: 839720,
          mortalityRate: 126.5,
        },
      },
    },
  ],
};
