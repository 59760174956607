import { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div>
        {/* <footer class="bg-afroOceanBlue">
          <div class="p-4">
            <div class="container row">
              <div class="col-md-3 footer_1 footer_region">
                <div class="region region-footer-1">
                  <nav
                    role="navigation"
                    aria-labelledby="block-helpandservices-menu"
                    id="block-helpandservices"
                  >
                    <h2 className="text-white footer-title h3">
                      Information and Services
                    </h2>

                    <ul>
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/news/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          News
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/opportunities/"
                          target="_blank"
                        >
                          Careers
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/disease/"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="node/4238"
                        >
                          Disease Information
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/disease-outbreak/"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="node/5103"
                        >
                          Disease Outbreaks
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/resources/"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="node/5103"
                        >
                          Resources
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/institutes/"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="node/5103"
                        >
                          Africa CDC Institutes
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-md-3 footer_2 footer_region">
                <div class="region region-footer-2">
                  <nav
                    role="navigation"
                    aria-labelledby="block-contactus-menu"
                    id="block-contactus"
                  >
                    <h2 className="text-white footer-title h3">Contact us</h2>

                    <ul class="text-white">
                      <li class="mb-3">
                        <a
                          className="fs-5 text-white"
                          href="https://africacdc.org/contact-us/"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="node/3565"
                        >
                          Inquiries
                        </a>
                      </li>
                      <li class="last">
                        <a
                          className="fs-5 text-white"
                          href="https://www.afro.who.int/countries"
                          target="_blank"
                          rel="noreferrer"
                          data-drupal-link-system-path="countries"
                        >
                          WHO country offices
                        </a>
    </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-md-3 footer_3 footer_region">
                <div class="region region-footer-3">
                  <nav
                    role="navigation"
                    aria-labelledby="block-whooffices-menu"
                    id="block-whooffices"
                  >
                    <h2 className="text-white footer-title h3">
                      Regional Centres
                    </h2>

                    <ul class="menu menu--who-offices nav">
                      <li className="mb-3">
                        <a
                          className="text-white  fs-5"
                          href="https://africacdc.org/rcc/central-africa-rcc/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          ACDC Central Africa RCC
                        </a>
                      </li>

                      <br />
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/rcc/eastern-africa-rcc/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          ACDC Eastern Africa RCC
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/rcc/northern-africa-rcc/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          ACDC Northern Africa RCC
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/rcc/southern-africa-rcc/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          ACDC Southern Africa RCC
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://africacdc.org/rcc/western-africa-rcc/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          ACDC Western Africa RCC
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-md-3 footer_4 footer_region">
                <div class="region region-footer-4">
                  <nav
                    role="navigation"
                    aria-labelledby="block-followus-menu"
                    id="block-followus"
                  >
                    <h2 className="text-white footer-title h3">Follow us</h2>

                    <ul className="text-white">
                      <li className="mb-3">
                        <a
                          className="text-white fs-5"
                          href="https://twitter.com/AfricaCDC"
                          target="_blank"
                        >
                          Twitter
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          href="https://www.facebook.com/africacdc/?_rdc=1&_rdr"
                          className="text-white fs-5"
                          target="_blank"
                        >
                          Facebook
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          href="https://www.youtube.com/channel/UCWRIrWg4as6umiFK_k80pIg"
                          className="fs-5 text-white"
                          target="_self"
                        >
                          YouTube
                        </a>
                      </li>
                      <li className="mb-3">
                        <a
                          href="https://www.linkedin.com/company/africacdc/"
                          target="_blank"
                          rel="noreferrer"
                          className="fs-5 text-white"
                          data-drupal-link-system-path="node/4295"
                        >
                          Linkedin
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </footer>*/}
        <div className="bg-afroSkyBlue p-3 displayFlex justifyCenter itemsCenter">
          <p className="text-white fs-4">
            &copy; All rights reserved Africa CDC
          </p>
        </div>
      </div>
    );
  }
}
export default Footer;
