import React, { Component } from "react";
import Header from "../components/Header";
import LineGraph from "../components/LineGraph";
import SideBar from "../components/SideBar";
import { ResponsiveContainer } from "recharts";
import JVMAP from "../components/JVMAP";

class SDGDashboard extends Component {
  state = {};
  render() {
    return (
      <div>
        <Header />
        <SideBar />
        <div class="content-body">
          <div className="row" style={{"margin-left":"20px", "margin-top":"10px"}}>
            <div className="col-md-3">
              <select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
              >
                <option selected>Select Region</option>
                <option value="1">Burundi</option>
                <option value="2">Cameroon</option>
                <option value="3">Central African Republic</option>
                <option value="3">Chad</option>
                <option value="3">Congo</option>
                <option value="3">Democratic Republic of the Congo</option>
                <option value="3">Equatorial Guinea</option>
                <option value="3">Gabon</option>
                <option value="3">United Republic of Tanzania</option>
                <option value="3">Uganda</option>
                <option value="3">South Sudan</option>
              </select>
            </div>
            <div className="col-md-3">
              <select
                class="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
              >
                <option selected>Select Period</option>
                <option value="1">2023</option>
                <option value="2">2022</option>
                <option value="3">2021</option>
                <option value="3">2020</option>
                <option value="3">2019</option>
                <option value="3">2018</option>
                <option value="3">2017</option>
                <option value="3">2016</option>
                <option value="3">2015</option>
                <option value="3">2014</option>
                <option value="3">2013</option>
                <option value="3">2012</option>
                <option value="3">2011</option>
              </select>
            </div>
          </div>
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12 col-xxl-12">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="row">
                          <div class="col-xl-3 col-sm-6">
                            <div class="card bg-primary text-white">
                              <div class="card-header border-0 flex-wrap">
                                <div class="revenue-date">
                                  <span>SDG Cases</span>
                                  <h4 class="text-white">310 435</h4>
                                </div>
                                <div class="avatar-list avatar-list-stacked me-2"></div>
                              </div>
                              <div class="card-body pb-0 custome-tooltip d-flex align-items-center">
                                <div id="chartBar" class="chartBar"></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-3 col-sm-6">
                            <div class="card bg-secondary text-white">
                              <div class="card-header border-0">
                                <div class="revenue-date">
                                  <span class="text-black">
                                    SDG Case Fatality Rate
                                  </span>
                                  <h4 class="text-black">45 %</h4>
                                </div>
                                <div class="avatar-list avatar-list-stacked me-2">
                                  <span class="avatar rounded-circle bg-white">
                                    <a href="#">
                                      <svg
                                        width="14"
                                        height="15"
                                        viewBox="0 0 14 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M5.83333 6.27083V1.60417C5.83333 0.959834 6.35567 0.4375 7 0.4375C7.64433 0.4375 8.16667 0.959834 8.16667 1.60417V6.27083H12.8333C13.4777 6.27083 14 6.79317 14 7.4375C14 8.08183 13.4777 8.60417 12.8333 8.60417H8.16667V13.2708C8.16667 13.9152 7.64433 14.4375 7 14.4375C6.35567 14.4375 5.83333 13.9152 5.83333 13.2708V8.60417H1.16667C0.522334 8.60417 0 8.08183 0 7.4375C0 6.79317 0.522334 6.27083 1.16667 6.27083H5.83333Z"
                                          fill="#222B40"
                                        />
                                      </svg>
                                    </a>
                                  </span>
                                </div>
                              </div>
                              <div class="card-body pb-0 custome-tooltip d-flex align-items-center">
                                <div id="expensesChart" class="chartBar"></div>
                                <div>
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="10"
                                      cy="10"
                                      r="10"
                                      fill="#222B40"
                                    />
                                    <g clip-path="url(#clip0_3_473)">
                                      <path
                                        opacity="0.3"
                                        d="M13.0641 7.54535C13.3245 7.285 13.3245 6.86289 13.0641 6.60254C12.8038 6.34219 12.3817 6.34219 12.1213 6.60254L6.46446 12.2594C6.20411 12.5197 6.20411 12.9419 6.46446 13.2022C6.72481 13.4626 7.14692 13.4626 7.40727 13.2022L13.0641 7.54535Z"
                                        fill="white"
                                      />
                                      <path
                                        d="M7.40728 7.26921C7.03909 7.26921 6.74061 6.97073 6.74061 6.60254C6.74061 6.23435 7.03909 5.93587 7.40728 5.93587H13.0641C13.4211 5.93587 13.7147 6.21699 13.7302 6.57358L13.9659 11.9947C13.9819 12.3626 13.6966 12.6737 13.3288 12.6897C12.9609 12.7057 12.6498 12.4205 12.6338 12.0526L12.4258 7.26921H7.40728Z"
                                        fill="white"
                                      />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_3_473">
                                        <rect
                                          width="16"
                                          height="16"
                                          fill="white"
                                          transform="matrix(-1 0 0 -1 18 18)"
                                        />
                                      </clipPath>
                                    </defs>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-3 col-sm-6">
                            <div class="card">
                              <div class="card-body depostit-card">
                                <div class="depostit-card-media d-flex justify-content-between style-1">
                                  <div>
                                    <h6>Progress Towards SDGs</h6>
                                    <h3>20</h3>
                                  </div>
                                  <div class="icon-box bg-secondary">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clip-path="url(#clip0_3_566)">
                                        <path
                                          opacity="0.3"
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M8 3V3.5C8 4.32843 8.67157 5 9.5 5H14.5C15.3284 5 16 4.32843 16 3.5V3H18C19.1046 3 20 3.89543 20 5V21C20 22.1046 19.1046 23 18 23H6C4.89543 23 4 22.1046 4 21V5C4 3.89543 4.89543 3 6 3H8Z"
                                          fill="#222B40"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M10.875 15.75C10.6354 15.75 10.3958 15.6542 10.2042 15.4625L8.2875 13.5458C7.90417 13.1625 7.90417 12.5875 8.2875 12.2042C8.67083 11.8208 9.29375 11.8208 9.62917 12.2042L10.875 13.45L14.0375 10.2875C14.4208 9.90417 14.9958 9.90417 15.3792 10.2875C15.7625 10.6708 15.7625 11.2458 15.3792 11.6292L11.5458 15.4625C11.3542 15.6542 11.1146 15.75 10.875 15.75Z"
                                          fill="#222B40"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M11 2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2H14.5C14.7761 2 15 2.22386 15 2.5V3.5C15 3.77614 14.7761 4 14.5 4H9.5C9.22386 4 9 3.77614 9 3.5V2.5C9 2.22386 9.22386 2 9.5 2H11Z"
                                          fill="#222B40"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_3_566">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                </div>
                                <div class="progress-box mt-0">
                                  <div class="d-flex justify-content-between">
                                    <p class="mb-0">Complete Task</p>
                                    <p class="mb-0">20/28</p>
                                  </div>
                                  <div class="progress">
                                    <div
                                      class="progress-bar bg-secondary"
                                      style={{
                                        width: 50,
                                        height: 5,
                                        "border-radius": 4,
                                      }}
                                      role="progressbar"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-xl-3 col-sm-6">
                            <div class="card">
                              <div class="card-body depostit-card">
                                <div class="depostit-card-media d-flex justify-content-between style-1">
                                  <div>
                                    <h6>Progress Towards Reduction</h6>
                                    <h3>20</h3>
                                  </div>
                                  <div class="icon-box bg-secondary">
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g clip-path="url(#clip0_3_566)">
                                        <path
                                          opacity="0.3"
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M8 3V3.5C8 4.32843 8.67157 5 9.5 5H14.5C15.3284 5 16 4.32843 16 3.5V3H18C19.1046 3 20 3.89543 20 5V21C20 22.1046 19.1046 23 18 23H6C4.89543 23 4 22.1046 4 21V5C4 3.89543 4.89543 3 6 3H8Z"
                                          fill="#222B40"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M10.875 15.75C10.6354 15.75 10.3958 15.6542 10.2042 15.4625L8.2875 13.5458C7.90417 13.1625 7.90417 12.5875 8.2875 12.2042C8.67083 11.8208 9.29375 11.8208 9.62917 12.2042L10.875 13.45L14.0375 10.2875C14.4208 9.90417 14.9958 9.90417 15.3792 10.2875C15.7625 10.6708 15.7625 11.2458 15.3792 11.6292L11.5458 15.4625C11.3542 15.6542 11.1146 15.75 10.875 15.75Z"
                                          fill="#222B40"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M11 2C11 1.44772 11.4477 1 12 1C12.5523 1 13 1.44772 13 2H14.5C14.7761 2 15 2.22386 15 2.5V3.5C15 3.77614 14.7761 4 14.5 4H9.5C9.22386 4 9 3.77614 9 3.5V2.5C9 2.22386 9.22386 2 9.5 2H11Z"
                                          fill="#222B40"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_3_566">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </div>
                                </div>
                                <div class="progress-box mt-0">
                                  <div class="d-flex justify-content-between">
                                    <p class="mb-0">Complete Task</p>
                                    <p class="mb-0">20/28</p>
                                  </div>
                                  <div class="progress">
                                    <div
                                      class="progress-bar bg-secondary"
                                      style={{
                                        width: 50,
                                        height: 5,
                                        "border-radius": 4,
                                      }}
                                      role="progressbar"
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-6">
                        <div class="card overflow-hidden">
                          <div class="card-header border-0 pb-0 flex-wrap">
                            <h4 class="heading mb-0">
                              Trends over the past few Years
                            </h4>
                          </div>
                          <div className="card-body">
                            <br></br>
                            <br></br>

                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "400px",
                                paddingBottom: "250px",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  left: 0,
                                  right: 0,
                                  bottom: 0,
                                  top: 0,
                                }}
                              >
                                <ResponsiveContainer>
                                  <LineGraph />
                                </ResponsiveContainer>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-6">
                        <div class="card">
                          <div class="card-header border-0 flex-wrap">
                            <h4 class="heading mb-0">
                              Areas with Highest SDG Cases
                            </h4>
                          </div>
                          <div class="card-body p-0">
                            <JVMAP />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-12 col-xxl-12">
                <div class="card">
                  <div class="card-body p-0">
                    <div class="table-responsive active-projects">
                      <div class="tbl-caption">
                        <h4 class="heading mb-0">Active Projects</h4>
                      </div>
                      <table id="projects-tbl" class="table">
                        <thead>
                          <tr>
                            <th colSpan={2}>Indicatior</th>
                            <th colSpan={2}>Ranking in Africa</th>
                            <th colSpan={2}>African Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>SDG Incident Rate</td>
                            <td>
                              <div class="d-flex align-items-center">
                                <p class="mb-0 ms-2"></p>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="tbl-progress-box">
                                <span class="text-primary">1</span>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="avatar-list avatar-list-stacked"></div>
                            </td>
                            <td class="pe-0"></td>
                            <td>
                              <span>17 000</span>
                            </td>
                          </tr>

                          <tr>
                            <td>SDG Case Fatality Rate</td>
                            <td>
                              <div class="d-flex align-items-center">
                                <p class="mb-0 ms-2"></p>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="tbl-progress-box">
                                <span class="text-primary">17</span>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="avatar-list avatar-list-stacked"></div>
                            </td>
                            <td class="pe-0"></td>
                            <td>
                              <span>508</span>
                            </td>
                          </tr>

                          <tr>
                            <td>Progress towards SDGs</td>
                            <td>
                              <div class="d-flex align-items-center">
                                <p class="mb-0 ms-2"></p>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="tbl-progress-box">
                                <span class="text-primary">12</span>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="avatar-list avatar-list-stacked"></div>
                            </td>
                            <td class="pe-0"></td>
                            <td>
                              <span>20%</span>
                            </td>
                          </tr>

                          <tr>
                            <td>Progress Towards SDG Reduction</td>
                            <td>
                              <div class="d-flex align-items-center">
                                <p class="mb-0 ms-2"></p>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="tbl-progress-box">
                                <span class="text-primary">15</span>
                              </div>
                            </td>
                            <td class="pe-0">
                              <div class="avatar-list avatar-list-stacked"></div>
                            </td>
                            <td class="pe-0"></td>
                            <td>
                              <span>20%</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SDGDashboard;
