export const selectCountry = (country) => {
  return (dispatch) => {
    dispatch({ type: "SELECT_COUNTRY", countryName: country });
  };
};

export const selectPeriod = (period) => {
  return (dispatch) => {
    dispatch({ type: "SELECT_PERIOD", period: period });
  };
};

export const selectRegion = (region) => {
  return (dispatch) => {
    dispatch({ type: "SELECT_REGION", region: region });
  };
};

export const filterData = (country, period) => {
  return (dispatch) => {
    dispatch({ type: "FILTER_DATA", country: country, period: period });
  };
};
