import React from "react";

const OldPowerBi = () => {
  return (
    <div>
      <iframe
        className="p-4"
        title="WHO Data Hub"
        width="1540"
        height="760.25"
        src="https://app.powerbi.com/reportEmbed?reportId=fb89ddaa-48b4-41d9-b533-d34b80806807&autoAuth=true&ctid=2903a696-28cb-4a58-be0b-11031720598f"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default OldPowerBi;
