import React from "react";

const PowerBi = () => {
  return (
    <div>
      <iframe
        title="ACDC Dashboard"
        width="1540"
        height="760.25"
        src="https://app.powerbi.com/reportEmbed?reportId=e3acd2f8-1f63-4a21-a21e-c099338977e7&autoAuth=true&ctid=2903a696-28cb-4a58-be0b-11031720598f"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default PowerBi;
